.guest-modal-img .ant-image .ant-image-img{
    height: 564px;
    object-fit: cover;
}

@media (max-width: 992px) {

    .guest-modal-img .ant-image .ant-image-img{
        /* width: 632px; */
        height: 365px;
        margin-top: 15px;
    }

    .modal-close {
        top: 10px !important;
        right: 20px !important;
        font-size: 20px !important;
    }
}

@media (max-width: 426px) {
    .guest-modal-img .ant-image .ant-image-img{
        /* max-width: 327px; */
        height: 203px;
        margin-top: 1rem;
    }

    .modal-close {
        top: 10px !important;
        right: 10px !important;
        font-size: 20px !important;
    }
}