.custom-book-btn {
    padding: 0.75rem 3rem !important;
    border-radius: 1.5rem !important;
    background: transparent;
}

.custom-select-dropdown {
    padding: 1.4rem !important;
    border-radius: 1.5rem !important;
    background: transparent;
    border: 1px solid #A0A1AB;
}

.custom-select-dropdown .ant-select-selector {
    border: none !important;
    background: transparent !important;
    padding: 0 !important;
    box-shadow: none !important;
}

.custom-select-dropdown .ant-select-arrow {
    color: black;
}

@media (max-width: 425px) {
    .custom-book-btn{
      width: 100%;
    }
}

@media (max-width: 991px) and (min-width: 426px) { 
    .custom-choose-container {
        border: 1px solid #E4E4E7;
        border-radius: 24px;
        padding: 1.5rem !important;
    }
}

@media (max-width: 425px) { 
    .custom-choose-container {
        padding: 0 !important;
    }
}
