.failed-animation {
  width: 70px; 
  margin: 0 auto;
}

.banner-wrapper-failure {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 440px;
  overflow: hidden;
  object-fit: cover !important;
  pointer-events: none;
}

.gradient-overlay-failure {
  background: linear-gradient(
    180deg, 
    rgba(255, 255, 255, 0) 0%, 
    rgba(255, 255, 255, 0.8) 50%, 
    #FFFFFF 75%
  );
  position: relative;
  width: 100%;
}