
.custom-steps .ant-steps-item-container {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;
    text-align: center;
    width: 100%;
    position: relative;
}

.custom-steps .ant-steps-item-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px !important;
    height: 50px !important;
    border-radius: 50%;
    font-size: 16px;
    background-color: #2263E3 !important;
    position: relative;
    z-index: 2;
    padding: 0 10px !important;
}
  
.custom-steps .ant-steps-item-content {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 10px; 
}

.custom-steps .ant-steps-item-title {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    flex-grow: 1;
    color: black !important;
}

.custom-steps .ant-steps-item-process .ant-steps-item-title {
    color: #2263E3 !important;
}
  
.custom-steps .ant-steps-item:last-child {
    flex: 1;
    min-width: 120px;
}

.custom-steps .ant-steps-item-finish .ant-steps-item-icon {
    background-color: black !important;
} 

.custom-steps .ant-steps-item-wait .ant-steps-item-icon {
    background-color: white !important;
    border: 1px solid black !important;
}

.custom-steps .ant-steps-item-wait .ant-steps-item-icon .custom-step-icon {
    color: black !important;
}

.custom-steps .ant-steps-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-steps .ant-steps-item-tail {
    display: block !important;
    position: absolute;
    top: 30%;
    left: 50%;
    width: 100%;
    height: 2px;
    background-color: #A0A1AB !important;
    transform: translate(-50%, -50%);
}

.custom-steps .ant-steps-item:first-child .ant-steps-item-tail {
    left: 70%;
    width: 50%;
    transform: translateX(0);
    top: calc(30% - 1px); 
}

.custom-steps .ant-steps-item:last-child .ant-steps-item-tail {
    left: -25%;
    width: 50%;
    transform: translateX(0);
    top: calc(30% - 1px); 
}

.custom-steps .ant-steps-item-title::after {
    display: none !important;
}

.custom-steps .ant-steps-item {
    padding-inline-start: 0 !important;
}

.custom-steps .ant-steps-item-tail::after {
    background: #A0A1AB !important;
}

@media (min-width: 992px) {
    .submit-mob{
        display: none !important;
    }
}

@media (max-width: 992px) {
    .submit-web{
        display: none !important;
    }
}

@media screen and (max-width: 575px) {
    .custom-steps {
        border: 1px solid #A0A1AB;
        border-radius: 8px;
    }

    .custom-steps .ant-steps-item-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px !important;
        height: 50px !important;
        border-radius: 50%;
        font-size: 14px !important;
        background-color: black !important;
        margin-left: 10px !important;
    }

    .custom-steps .ant-steps-item-process .ant-steps-item-title {
        color: black !important;
    }

    .custom-steps .ant-steps-item-container {
        flex-direction: row;
        align-items: center;
    }

    .step-title {
        font-size: 16px;
        font-weight: bold;
        color: black;
    }

    .step-count {
        font-size: 14px;
        color: #6c757d;
    }

    .custom-steps .ant-steps-item .ant-steps-item-content {
        display: flex !important;
        justify-content: start !important;
        align-items: start !important;
    }

    .custom-steps .ant-steps-item-tail {
        display: none !important;
    }

}
