.newupdateForm .ant-input-lg {
    padding: 11.5px 24px !important;
    border-radius: 2rem;
}

.newupdateForm .ant-btn-default:not(:disabled):not(.ant-btn-disabled):active {
    color: #fff !important;
    border-color: #ffffff00 !important;
}

.newupdateForm .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #fff !important;
    border-color: #ffffff00 !important;
}

.background-image-row {
    position: relative;
    height: 300px;
}

.custom-placeholder-input::placeholder {
    color: black !important;
    opacity: 1;
}

  
