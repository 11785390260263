.direct-btn{
    border: 1px solid;
}

.food-rate{
    background-color: #1D923D;
}

.badge-custom2 {
    background-color: #FFDDDA;
    border-radius: 5px;
    padding: 2px 10px;
    display: inline-block;
}

.badge-custom2 :where(.ant-badge-status .ant-badge-status-text) {
    color: #AE2100 !important;
}

@media (max-width: 425px) {
    .direct-btn{
        width: 100%;
    }

    .food-content{
        text-align: center !important;
    }

    .ratings{
        align-items: center;
        justify-content: center;
    }

    .non-vegbanner {
        content: url('../../../assets/images/dining/nonveg/img-non-veg-foodbanner-mobile.webp');
        width: 100%;
    }
}

@media (min-width: 1440px) {
    .non-vegbanner {
        content: url('../../../assets/images/dining/nonveg/img-non-veg-foodbanner-1920px.webp');
        width: 100%;
    }
}

@media (max-width: 1440px) {
    .non-vegbanner {
        content: url('../../../assets/images/dining/nonveg/img-non-veg-foodbanner-1440px.webp');
        width: 100%;
    }
}

@media (max-width: 834px) {
    .non-vegbanner {
        content: url('../../../assets/images/dining/nonveg/img-non-veg-foodbanner-tab.webp');
        width: 100%;
    }
}

@media (max-width: 767px) {
    .direction-btn {
        text-align: start !important;
    }
}
 