.section-number-4 {
    background-color: #303136;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}

.booking-border-bottom{
  border-bottom: 1px solid #E4E4E7 !important;
}

@media (min-width: 426px) {
  .merchan-food-img{
    width: 64px !important;
    height: 64px !important;
  }
} 
  
@media (max-width: 426px) {
    .section-number-4{
      width: 30px !important;
      height: 30px !important;
      padding: 1.2rem !important;
    }
  
    .merchan-food-img{
      width: 64px !important;
      height: 64px !important;
      border-radius: 8px !important;
    }

    .merchandise-content {
      margin: 7px !important;
    }

    .merchandise-button{
      padding: 2px 10px !important;
    }
}

@media (max-width: 1199px) and (min-width: 560px) {
  .merchandise-modal {
    width: 550px !important;
  }
}

@media (max-width: 560px) and (min-width: 320px) {
  .merchandise-modal {
    width: 350px !important;
  }
}
    