.olivier-font {
  font-family: 'Olivier', sans-serif !important;
}

.custom-placeholder::placeholder {
  color: white;
  opacity: 1;
}

@media (max-width: 425px) {
  .olivier-font {
    font-size: 36px !important;
  }

  .notify-para{
    font-size: 32px !important;
  }
}
