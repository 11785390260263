.bookingslider .swiper-pagination-bullet {
    width: 40px;
    height: 5px;
    background: #CCCDD1;
    opacity: 1;
    transition: background 0.3s;
    border-radius: 0;
}

.bookingslider .swiper-pagination-bullet-active {
    background: #007aff;
    width: 28px;
    border-radius: 5px;
}

.booking-slide {
    min-width: 70%;
    max-width: 70%;
    margin: 0 auto;
}

.swiper-button-prev, .swiper-button-next {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.swiper-button-prev::after, .swiper-button-next::after {
    color: #000;
    font-size: 18px;
}

.custom-slide-images .ant-image {
    display: block !important;
}

@media (min-width: 425.98px) {
    .img-container{
        padding-left: 5rem !important;
    }
}
