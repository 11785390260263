@import 'bootstrap/dist/css/bootstrap.min.css';
@font-face {
  font-family: 'Olivier';
  src: url('./fonts/olivier.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rushk';
  src: url('./fonts/Rushk.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CaveatBrush';
  src: url('./fonts/CaveatBrush-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


* {
  font-family: 'Open Sans' !important;
}

.header {
  font-family: 'Rushk' !important;
  background: linear-gradient(271.41deg, #0256B8 57.82%, #F8B21E 57.99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.sub-header {
  font-family: 'CaveatBrush' !important;
}

.cursor-pointer {
  cursor: pointer;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}


.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-28 {
  font-size: 28px !important;
}


.fs-30 {
  font-size: 30px !important;
}

.fs-32 {
  font-size: 32px !important;
}

.fs-36 {
  font-size: 36px !important;
}

.fs-40 {
  font-size: 40px !important;
}

.fs-42 {
  font-size: 42px !important;
}

.fs-44 {
  font-size: 44px !important;
}

.fs-48 {
  font-size: 48px !important;
}

.fs-56 {
  font-size: 56px !important;
}

.fs-60 {
  font-size: 60px !important;
}

.fs-104 {
  font-size: 104px !important;
}

.fs-64 {
  font-size: 64px !important;
}

.fs-120 {
  font-size: 120px !important;
}

.fw-700 {
  font-weight: 700;
}

.fw-600 {
  font-weight: 600;
}

.fw-800 {
  font-weight: 800;
}

.fw-1000 {
  font-weight: 1000;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-800 {
  font-weight: 800;
}

.h-100vh {
  height: 100vh;
}

.ant-btn-primary {
  border-color: #fff;
  border: 2px;
  background: linear-gradient(to bottom, #0258BD, #3993FC) !important;
}

.ant-btn-primary:hover {
  background: linear-gradient(to left, #3993FC, #0258BD) !important;
  border-color: #fff;
  border: 2px;
}

.btn-blk-secondary {
  border-color: #1F2937 !important;
  color: #1F2937 !important;
}

.btn-danger {
  border-color: #C83708;
  border: 2px;
  background: linear-gradient(180deg, #E5CE02 11.33%, #FADF52 100%) !important;
  color: black;
}

.btn-danger:hover {
  background: linear-gradient(180deg, #E5CE02 11.33%, #FADF52 100%) !important;
  border-color: #C83708;
  border: 2px;
  color: #ffff;
}

.bg-blue {
  background: #0030BC;
}

.bg-dark-blue {
  background: #0256B8;
}

.bg-light-blue {
  background: #DBEBFE;
}

.bg-light-red {
  background: #FFDDDA;
}

.bg-dark-red {
  background: #AE2100;
}

.bg-light-yellow {
  background: #FFF6D1;
}

.bg-dark-yellow {
  background: #BD5B02;
}

.bg-light-green {
  background: #CEF7DA;
}

.bg-dark-green {
  background: #006836;
}

.bg-green {
  background: #32CC64;
}

.bg-light-purple{
  background: #DDD9F2;
}

.bg-dark-purple{
  background: #453597;
}

.bg-light-grey{
  background: #F1F1F3;
}

.bg-light-teal {
  background: #B2DFDB;
}

.bg-dark-teal {
  background: #004D40;
}

.text-black {
  color: #303136 !important
}

.acc-head{
  color: #60616C !important;
}

.acc-text{
  color: #303136 !important;
}

.ant-btn-lg {
  height: 48px !important;
}

.booking-btn {
  padding: 0.75rem 1.5rem !important;
  border-radius: 1.5rem !important;
  background: transparent;
}

.bg-dark-yellow-1 {
  background-color: #FAD657;
}

.pointer-event-none {
  pointer-events: none !important;
}

.ant-image {
  /* width: 100% !important; */
  /* height: 100% !important; */
  overflow: hidden;
  position: relative;
}

.galleryimg{
  border-radius: 20px;
}

.download-btn {
  border: 1px solid;
}

.visit-btn{
  border: 1px solid;
}
.locker-btn{
  border: 1px solid;
}

.border-bottom{
  border-bottom: 1px solid #A0A1AB !important;
}

.border-top{
  border-top: 1px solid #A0A1AB !important;
}



/* Account Page*/

.forms{
  border-radius: 20px;
}

.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control, .input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select, .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating){
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-right: none;
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-left: none;
}

.floating-label-group {
  position: relative;
  margin-bottom: 20px;
}

.floating-input {
  width: 100%;
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 30px;
  outline: none;
  transition: border-color 0.3s;
}

.floating-input:focus {
  border-color: #007bff;
}

.floating-label {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  padding: 0 5px;
  font-size: 14px;
  color: #999;
  transition: all 0.3s ease;
  background-color: #fff; 
}

.floating-input:not(:placeholder-shown) + .floating-label,
.floating-input:focus + .floating-label {
  top: 0;
  left: 15px;
  font-size: 12px;
  color: black;
  padding: 0 5px;
}

.ant-tabs-left >.ant-tabs-content-holder{
  border-left: none;
}

.upload-btn{
  background: linear-gradient(180deg, #0258BD 11.33%, #3993FC 100%);
}

.gradient-text {
  background: linear-gradient(270.19deg, #3993FC 22.67%, #0258BD 67.83%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.banner-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 400px;
  overflow: hidden;
  object-fit: cover !important;
  pointer-events: none;
}

.gradient-overlay {
  background: linear-gradient(180deg, 
    rgba(255, 255, 255, 0) -11.81%, 
    rgba(255, 255, 255, 0.8) 8%, 
    #FFFFFF 20%);
  width: 100%;
  position: relative;
}


@media (min-width: 834px) {
  .header {
    font-size: 56px;
  }
}

@media (max-width: 834px) {
  .header {
    font-size: 34px;
  }
}

@media (max-width: 426px) {
  .sub-heading-text {
    font-size: 16px;
  }

  .booking-btn{
    width: 100%;
  }
}

@media (min-width: 425px) {
  .sub-heading-text {
    font-size: 18px;
  }
}