@media (max-width: 1920px) and (min-width: 425.98px) {
    .my-masonry-grid {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-left: -30px; 
        width: auto;
    }
    .my-masonry-grid_column {
        padding-left: 20px; 
        background-clip: padding-box;
    }
}