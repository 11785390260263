.floating-form-item {
  position: relative;
  margin-bottom: 2.5rem;
}

.floating-input::placeholder {
  color: transparent; 
}

.floating-input:focus + .floating-label,
.floating-input:not(:placeholder-shown) + .floating-label {
  top: -10px; 
  left: 15px; 
  transform: translateY(0); 
}

.section-number-2 {
  background-color: #303136;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.booking-border-bottom{
  border-bottom: 1px solid #E4E4E7 !important;
}

@media (min-width: 426px) {
  .ticket-avatar{
    width: 64px !important;
    height: 64px !important;
  }
}

@media (max-width: 426px) {
  .ticket-button{
    padding: 2px 10px !important;
  }

  .section-number-2{
    width: 30px !important;
    height: 30px !important;
    padding: 1.2rem !important;
  }

  .ticket-row{
    padding: 5px 0 !important;
    /* margin: 0 !important; */
  }

  .voucher-col{
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .ticket-form{
    margin: 1rem !important;
  }

  .ticket-avatar{
    display: none;
  }

  .ticket-content{
    margin: 7px !important;
  }

  .ticket-img-div {
    margin-right: 0 !important;
  }
}


