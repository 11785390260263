.dest-select .ant-select-outlined .ant-select-selector {
    background: #F8F8F9 !important;
    border-radius: 16px;
    overflow: hidden;
}

.custom-select .ant-select-selection-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.custom-select .ant-select-selection-overflow {
    display: flex;
    justify-content: flex-end;
}

.custom-book-btn {
    padding: 0.75rem 4rem !important;
    border-radius: 1.5rem !important;
    background: transparent;
}

.custom-back-btn {
    padding: 0.75rem 4rem !important;
    border-radius: 1.5rem !important;
    background: transparent;
}

@media (max-width: 425px) {
    .custom-book-btn{
      width: 100%;
    }

    .custom-back-btn {
        width: 100%;
    }
}

@media (min-width: 992px) {
    .custom-back-btn {
        display: none;
    }
}

@media (max-width: 991px) and (min-width: 426px) { 
    .select-plan-container {
        border: 1px solid #E4E4E7;
        border-radius: 24px;
        padding: 1.5rem !important;
    }
}