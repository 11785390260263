.custom-tab-label {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 700;
    font-size: 16px;
    color: #60616C;
    border: 1px solid none;
    border-radius: 32px;
    padding: 12px;
}

.custom-tab-label.active {
    color: #14439F;
    border-radius: 32px;
    /* border-right: none; */
    background-color: #CFDCF6;
    padding: 12px;
    /* margin: 0 !important; */
    border-color: transparent;
}

.tab-key-circle {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background-color: #E6E6E6;
    color: #60616C;
    border-radius: 50%;
    font-weight: bold;
}

.custom-tab-label.active .tab-key-circle {
    background-color: #ffff;
    color: #14439F;
}

.tab-labels-scroll-container::-webkit-scrollbar {
    height: 2px;
    display: none;
}

.tab-labels-scroll-container::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 4px;
}

.tab-labels-scroll-container::-webkit-scrollbar-thumb:hover {
    background: #aaaaaa;
}

@media (min-width: 992px) {
    .custom-content-bg {
        background: linear-gradient(180deg, #FFF9FF 0%, #E8EEFF 100%);
        border-radius: 48px;
        padding: 1.5rem;
        /* margin: 2rem !important; */
    }

    .tab-labels-scroll-container {
        padding: 1.5rem !important;
    }

    .tab-content-container {
        padding: 1rem !important; 
    }

    .tab-heading-mob {
        display: none;
    }
    
}

@media (max-width: 992px) {
    .custom-tab-label {
        border: 1px solid #E4E4E7;
    }

    .tab-heading-web {
        display: none;
    }

    .tab-content-container{
        padding-top: 1rem;
    }
}

@media (max-width: 425px) {
    .tab-label-name {
        display: none;
    }

    .custom-tab-label.active .tab-label-name {
        display: inline;
    }
}










/* .custom-tab-label {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 700;
    font-size: 16px;
    color: #60616C;
}

.customTabs .ant-tabs-tab-active .custom-tab-label {
    color: #14439F;
}

.customTabs .ant-tabs-tab-active {
    border-radius: 24px 24px 24px 24px;
    border-right: none;
    background-color: #CFDCF6;
    padding: 12px !important;
    margin-left: 12px !important;
}

.tab-key-circle {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background-color: #E6E6E6;
    color: #60616C;
    border-radius: 50%;
    font-weight: bold;
}

.customTabs .ant-tabs-tab-active .tab-key-circle {
    background-color: #FFFF;
    color: #14439F;
} */
