.slide-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
}

.more-vlogs .swipper-slide {
  border-radius: 2rem !important;
}

@media (min-width: 1770px) {
  .more-vlogs .swiper-wrapper .swiper-slide {
    width: 450px !important;
  }
}

@media (max-width: 426px) {
  .slide-video {
    width: 390px !important;
    border-radius: 15px !important;
  }

  .more-vlogs-video {
    display: flex !important;
    text-align: center !important;
    justify-content: center !important;
    align-items: center !important;
  }
}