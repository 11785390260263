@media (min-width: 1440px) {
    .map-large-location{
       height: 447px !important;
    }
 }
 
 @media (max-width: 1440px) {
   .map-large-location{
      height: 447px !important;
   }
 }
 
 @media (max-width: 834px) {
   .map-large-location{
      height: 317px !important;
   }
 }
 @media (max-width: 425px) {
   .map-large-location{
      height: 317px !important;
   }
 }
