.offer-card {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.home-offer-package-btn {
  padding: 0.75rem 1.5rem !important;
  border-radius: 1.5rem !important;
  background: #FFFFFF;
  width: calc(50% - 40px);
  position: absolute;
  bottom: -50px;
  left: 72%;
  transform: translate(-50%, 0);
  opacity: 0;
  transition: opacity 0.3s ease-in-out, bottom 0.3s ease-in-out;
}

.learn-offer-package-btn {
  padding: 0.75rem 1.5rem !important;
  border-radius: 1.5rem !important;
  border: 1px solid black;
  width: calc(50% - 40px);
  position: absolute;
  bottom: -50px;
  left: 28%;
  transform: translate(-50%, 0);
  opacity: 0;
  transition: opacity 0.3s ease-in-out, bottom 0.3s ease-in-out;
  margin-right: 10;
}

.offer-card:hover .home-offer-package-btn {
  bottom: 20px;
  opacity: 1;
}
.offer-card:hover .learn-offer-package-btn {
  bottom: 20px;
  opacity: 1;
}

.home-offer-package-btn.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: inherit !important;
  border-color: #E6E6E6 !important;
}
.learn-offer-package-btn.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: inherit !important;
  border-color: #E6E6E6 !important;
}

.image-container {
  height: 250px;
  overflow: hidden;
  border-radius: 16px;
}

.offer-image {
  transform: translateY(-20%);
}

@media (max-width: 991px) and (min-width: 425px) {
  .image-container {
    height: 350px;
    overflow: hidden;
    border-radius: 16px;
  }

  .offer-image {
    transform: translateY(-15%);
  }

  .offer-container {
    justify-content: center;
  }
}
