.book-icons-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;

}

.text-inner {
  text-align: left;
}

@media (max-width: 834px){
  .text-inner{
    text-align: center;
  }
}

.ant-collapse {
  background-color: transparent !important;
  border: none !important;
}

.ant-collapse > .ant-collapse-item {
  margin-bottom: 2rem;
}

.ant-btn-dashed {
  background: #DBEBFE;
  border-color: #0256B8;
}

.ant-btn-dashed:hover {
  background: #DBEBFE !important;
  border-color: #0256B8 !important;
}

.ant-collapse .ant-collapse-content {
  border-top: none;
}

.offer-radio {
  display: flex;
  flex-direction: column; 
  width: 100%;
  padding: 0;
  margin: 0;
}

.offers-radio .offer-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 1rem;
  position: relative;
}

.radio-wrapper {
  position: absolute;
  left: 10px;
}

@media (max-width: 834px) {
.ant-btn.ant-btn-block {
  width: auto; 
}
}

@media (max-width: 768px) {
.content-section, .booking-sidebar {
    padding: 0 15px 0 15px;
}
}

.ant-collapse .ant-collapse-content{
  border-top: none;
}

@media(max-width:834px) {
  .book-btn{
    width: 30%;
  }
}

  .about-content {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3; 
    line-height: 1.5em; 
    max-height: 4.5em;
    cursor: pointer;
    transition: max-height 0.3s ease;
  }
  
  .about-content.expanded {
    -webkit-line-clamp: unset;
    max-height: none;
  }