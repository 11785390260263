.career-radio-wrapper {
    position: absolute;
    left: 20px;
    top: 25px;
}

.job-title{
    padding-left: 2.0rem;
}

.career-main{
    line-height: 1.0 !important;
}

.selected {
    border: 2px solid #484951 !important;
}

.career-detail-page .ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
    background: #000;
    transform: scale(0.5);
}

.career-detail-page .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: #000000;
    background-color: #ffffff;
}

.showCMobile {
    display: none;
}

.showCWeb {
    display: block;
}

@media (max-width: 425px) {
    .showCMobile {
        display: block;
        overflow: auto;
    }

    .showCWeb {
        display: none;
    }
}