.custom-ant-picker {
  border: 1px solid;
  width: 50px;
  border-radius: 50px;
  height: 50px;
}

.custom-ant-picker::placeholder {
  color: transparent;
}

.custom-ant-picker .ant-picker-input {
  display: none !important;
}

.confetti-container {
  position: absolute;
}

.tick-animation {
  width: 70px; 
  margin: 0 auto;
}

.code-container {
  display: inline-block;
  position: relative;
  border-radius: 5px;
  border: 1px dashed #303136;
  text-align: center;
  margin-top: 20px;
}

.code-label {
  position: absolute;
  top: -11px;
  left: 50%;
  transform: translateX(-50%);
  background: #BD5B02;
  z-index: 1;
  width: 50%;
}

.payment-btn{
  border: 1px solid;
}

.icon-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-style {
  font-size: 24px;
  color: #000;
}

.custom-calendar .ant-picker-dropdown {
  position: absolute;
  transform: translateX(-50%);
  width: auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.success-container {
  position: relative;
}

.success-gif-container {
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  width: 100%;
  max-width: 500px;
}

.banner-wrapper-success {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1000px;
  overflow: hidden;
  object-fit: cover !important;
  pointer-events: none;
}

.gradient-overlay-success {
  background: linear-gradient(
    180deg, 
    rgba(255, 255, 255, 0) 0%, 
    rgba(255, 255, 255, 0.8) 50%, 
    #FFFFFF 75%
  );
  position: relative;
  width: 100%;
}

.pdf-style {
  padding: 3rem !important;
  background-color: white;
}

@media (max-width: 767px) {
  .gradient-overlay-success {
    background: linear-gradient(
      180deg, 
      rgba(255, 255, 255, 0) 0%, 
      rgba(255, 255, 255, 0.8) 50%, 
      #FFFFFF 60%
    );
  }
}

@media (min-width: 834px) {
  .confetti-container {
    left: 34%;
    top: 10%;
  }
}

@media (max-width: 834px) {
  .confetti-container {
    left: 25%;
    top: 10%;
  }
}

@media (max-width: 426px) {
  .confetti-container {
    left: 6%;
  }

  .success-offer{
    text-align: center !important;
  }

  .payment-btn{
    width: 100%;
  }

  .successs-container {
    padding-bottom: 1.5rem !important;
  }

  .details-mob {
    padding: 0.5rem !important;
  }

  .icon-col {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 767px) { 
  .qr-mobile {
    display: none !important;
  }

  .qr-web {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .qr-mobile {
    display: block;
    text-align: center;
  }

  .qr-web {
    display: none !important;
  }

  .payment-ticekt-img {
    display: none !important;
  }
}
