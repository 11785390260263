.booking-heading{
   color: #60616C;
}

.border-bottom{
   border-bottom: 1px solid #A0A1AB !important;
}

.border-top{
   border-top: 1px solid #A0A1AB !important;
}