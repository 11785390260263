.offer-slide {
    max-width: 70%;
    border-radius: 60px;
    margin: 0 auto;
    margin-bottom: 1rem;
}
 
.offer-info {
    bottom: 1.5rem;
    left: 1.5rem;
    right: 1.5rem;
    border-radius: 2rem;
}

.home-offer-card {
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

.home-offer-btn {
    padding: 0.75rem 1.5rem !important;
    border-radius: 1.5rem !important;
    background: #FFFFFF;
    width: calc(50% - 40px);
    position: absolute;
    bottom: -50px;
    left: 72%;
    transform: translate(-50%, 0);
    opacity: 0;
    transition: opacity 0.3s ease-in-out, bottom 0.3s ease-in-out;
}

.learn-offer-btn {
    padding: 0.75rem 1.5rem !important;
    border-radius: 1.5rem !important;
    border: 1px solid black;
    width: calc(50% - 40px);
    position: absolute;
    bottom: -50px;
    left: 28%;
    transform: translate(-50%, 0);
    opacity: 0;
    transition: opacity 0.3s ease-in-out, bottom 0.3s ease-in-out;
    margin-right: 10;
}

.home-offer-card:hover .learn-offer-btn {
    bottom: 20px;
    opacity: 1;
}
.home-offer-card:hover .home-offer-btn {
    bottom: 20px;
    opacity: 1;
}

.home-offer-btn.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: inherit !important;
    border-color: #E6E6E6 !important;
}

.offer-info .ant-btn-default:not(:disabled):not(.ant-btn-disabled):active {
    color: beige !important;
    border-color: beige !important;
}

.offerSlider .swiper-pagination-bullet {
    width: 40px;
    height: 5px;
    background: #CCCDD1;
    opacity: 1;
    transition: background 0.3s;
    border-radius: 0;
}

.offerSlider .swiper-pagination-bullet-active {
    background: #007aff;
    width: 28px;
    border-radius: 5px;
}

.offerSlider {
    padding-bottom: 4rem;
}

.offerSlider .swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0;
}

.offerSlider .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0;
}

.offer-content {
    padding-right: 5rem;
}

.icon-box {
    background: #fff;
    padding: 5px;
    border-radius: 50%;
}

.offer-img img {
    border-radius: 2rem;
}

@media (max-width: 834.98px) and (min-width: 425.98px) {
    .offer-img {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .offer-slide-lft {
        order: 2;
    }

    .offer-slide-rgt {
        order: 1;
    }

    .offer-content {
        padding-right: 1rem;
    }

    .offer-slide {
        max-width: 75%;
    }

    .offer-info{
        bottom: 2.5rem;
        left: 2.5rem;
        right: 2.5rem;
    }
}

@media (max-width: 425px) {
    .offer-img {
        display: none;
    }

    .offer-slide {
        border-radius: 24px;
    }

    .offer-content {
        padding-right: 1rem;
    }

    .offer-info {
        bottom: 1rem;
        left: 1rem;
        right: 1rem;
    }

    .offer-slide-lft, .offer-slide-rgt {
        order: unset; 
    }
}