.icon-col {
  display: flex;
  flex-direction: column; 
  align-items: center; 
}

.icon-container {
  margin-bottom: 8px; 
  display: flex;
  align-items: flex-start; 
  justify-content: center;
}

.icons-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}
  
.ant-collapse {
  background-color: transparent !important;
  border: none !important;
}

.ant-collapse > .ant-collapse-item {
  margin-bottom: 2rem;
}

.ant-btn-dashed {
  background: #DBEBFE;
  border-color: #0256B8;
}

.css-dev-only-do-not-override-98ntnt.ant-btn-dashed:hover {
background: #DBEBFE !important;
border-color: #0256B8 !important;
}

.reserve-spot{
  margin-left: 2rem;
  margin-right: 2rem;
}

.offer-radio {
  display: flex;
  align-items: flex-start; 
  width: 100%;
  padding: 0;
  margin-top: 0;
}

.offers-radio .offer-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 1rem;
  position: relative;
}

.radio-wrapper {
  position: absolute;
  left: 10px;
}

.desti-booking-btn {
    margin: 5px; 
    transition: border-color 0.3s ease; 
}

.desti-booking-btn:hover {
    border-color: #484951; 
    border-width: 2px; 
}

.desti-booking-btn {
    border: 1px solid #A0A1AB; 
}

.ant-card .ant-card-body {
    padding: 20px 15px 20px 15px;
}

.text-icon {
  text-align: left;
}

@media (max-width: 1200px){
  .text-icon {
    text-align: center;
  }

  .icons-row {
    justify-content: flex-start;
  }
}

@media (max-width: 834px) {
  .ant-btn.ant-btn-block {
    width: auto; 
  }
}

@media (max-width: 768px) {
  .content-section, .booking-sidebar {
      padding: 0 15px 0 15px;
  }
}

.destbook-about-content {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3; 
  line-height: 1.5em; 
  max-height: 4.5em;
  cursor: pointer;
  transition: max-height 0.3s ease;
}

.destbook-about-content.expanded {
  -webkit-line-clamp: unset;
  max-height: none;
}

@media(max-width:426px) {
  .include-content{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3; 
    line-height: 1.5em; 
    max-height: 4.5em;
    cursor: pointer;
    transition: max-height 0.3s ease;
  }

  .button{
    width: 100%;
  }

  .text-icon{
    text-align: left;
  }
}

@media (max-width: 1400px) and (min-width: 1200px) {
  .booking-icons {
    padding-right: 12px;
  }
}
