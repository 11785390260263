.career-info-page .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #000;
    border-color: #000;
}

.career-btn-active {
    background-color: #000 !important;
    color: #fff !important;
}

.career-info-page .button-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    overflow-x: auto;
    padding: 0 10px;
}

.career-info-page .booking-btn {
    flex-shrink: 0;
    white-space: nowrap;
}

.career-info-page .button-container::-webkit-scrollbar {
    height: 5px;
}

.career-info-page .button-container::-webkit-scrollbar-track {
    background-color: #f0f0f0;
}

.career-info-page .button-container::-webkit-scrollbar-thumb {
    background-color: #888888a9;
    border-radius: 6px; 
}

.gradient-overlay-career {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 30%,
      #FFFFFF 60%
    );
    width: 100%;
    position: relative;    
}