.select-offer .ant-select-selector {
    padding: 20px !important;
    border-radius: 10px !important;
    border-color: #FFFFFF !important;
}

.select-offer .ant-select-arrow {
    color: black !important;
}

.select-date {
    padding: 0 15px;
}

@media (min-width: 992px) {
    .booking-summary-container {
        margin-top: 3rem !important;
    }
}