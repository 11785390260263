.custom-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.custom-list-item {
    display: flex;
    align-items: flex-start;
    gap: 8px;
}

.custom-package-group {
    height: 100px;
}

