.banner-wrapper-faq {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 580px;
    overflow: hidden;
    object-fit: cover;
}

.gradient-overlay-faq {
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.8) 46.22%,
        #FFFFFF 60%
    );
    width: 100%;
    position: relative;
    
}