.check-circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.anticon.check-circle svg {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.select-desti-img {
    border-radius: 16px;
    width: 164px !important;
    min-height: 200px !important;
    object-fit: cover;
}

.custom-book-btn {
    padding: 0.75rem 4rem !important;
    border-radius: 1.5rem !important;
    background: transparent;
}

.custom-back-btn {
    padding: 0.75rem 4rem !important;
    border-radius: 1.5rem !important;
    background: transparent;
}

@media (min-width: 992px) {
    .custom-back-btn {
        display: none;
    }

    .desti-description {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        overflow: hidden;
    }
}

@media (max-width: 1199px) and (min-width: 768px) {
    .select-desti-img {
        width: 240px !important;
        min-height: 120px !important;
    }

    .desti-description {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
}

@media (max-width: 425px) {
    .custom-book-btn{
        width: 100%;
    }
  
    .custom-back-btn {
        width: 100%;
    }

    .select-desti-img {
        width: 120px !important;
        min-height: 164px !important;
    }

    .desti-description {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
    }
}
