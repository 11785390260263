.text-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4; 
  line-height: 1.5em; 
  max-height: 6.5em;
}

.shopping-block{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
}

.shopping-image {
  width: 100%;
  height: auto;
}

.subtitle-container {
  padding: 0 15px;
  box-sizing: border-box;
}

@media (max-width: 425px) {
  .subtitle-container{
    padding: 0 15px 0 0 !important;
  }
}
