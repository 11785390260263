.floating-input::placeholder {
    color: transparent; 
}

.floating-input:focus + .floating-label,
.floating-input:not(:placeholder-shown) + .floating-label {
    top: -10px; 
    left: 15px; 
    transform: translateY(0); 
}

.signup-type .ant-select-selector {
    border-color: black !important;
    border-radius: 20px !important;
}

.signup {
    padding: 0px !important
} 