.position-relative {
    position: relative;
}

.object-cover {
    object-fit: cover;
}

.wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 16px;
}

.image-zoom {
    transition: transform 0.5s ease;
}

.wrapper:hover .image-zoom {
    transform: scale(1.2);
}

.wrapper img {
    height: 32rem !important;
    object-position: 50%;
}

.wrapper .ant-image {
    display: flex;
}

@media (max-width: 834.98px) {
    .wrapper img {
        height: 436px !important;
    }
}

@media only screen and (min-width: 1440px) {
    .wrapper img {
        height: 37.325rem !important;
        object-position: 50%;
    }
}

.wrapper:hover .card-head-ride {
    min-height: 130px;
}

.arrow-black {
    background-color: #FAD657;
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
}


.card-head-ride {
    background-color: #FFFFFF;
    bottom: 1.5rem;
    left: 1.5rem;
    right: 1.5rem;
    border-radius: 1rem;
}

.additional-text {
    opacity: 0;
    max-height: 0;
    transition: opacity 0.3s ease, max-height 0.3s ease;
    overflow: hidden;
    color: #fff;
}

.wrapper:hover .additional-text {
    opacity: 1;
    max-height: 150px;
}

@media (min-width: 500px) and (max-width: 767px) {
    .card-head-ride {
        left: 5.5rem;
        right: 5.5rem;
    }
}

@media (min-width: 380px) and (max-width: 500px) {
    .card-head-ride {
        left: 1.5rem;
        right: 1.5rem;
    }
}