.floating-form-item {
    position: relative;
    margin-bottom: 2.5rem;
}
  
.floating-input::placeholder {
    color: transparent; 
}
  
.floating-input:focus + .floating-label,
.floating-input:not(:placeholder-shown) + .floating-label {
    top: -10px; 
    left: 15px; 
    transform: translateY(0); 
}

.floating-input-billing {
  width: 100%;
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 30px;
  outline: none;
  transition: border-color 0.3s;
}

.section-number-5 {
  background-color: #303136;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.border-line {
  height: 2px;
  background-color: #A0A1AB;
}

@media (max-width: 425px) {
    .section-number-5{
      width: 30px !important;
      height: 30px !important;
      padding: 1.2rem !important;
    }

    .billing-form{
        margin: 0 !important;
    }

    .add-billing-container{
      background: transparent !important;
    }

}

@media (max-width: 990px) {
  .billing-continue-btn {
    display: block;
  }
}

@media (min-width: 992px) {
  .billing-continue-btn {
    display: none;
  }
}
