.floating-input::placeholder {
    color: transparent; 
}

.floating-input:focus + .floating-label,
.floating-input:not(:placeholder-shown) + .floating-label {
    top: -10px; 
    left: 15px; 
    transform: translateY(0); 
}

.google-login-btn div div {
    border-radius: 50px !important;
    border-color: black !important;
}
