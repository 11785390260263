.park-timings-table .ant-table-thead > tr > th {
  background-color: #2263E3 !important; 
}

.park-timings-table .custom-first-row {
  background-color: #FFFFFF !important; 
}

.park-timings-table .custom-second-row {
  background-color: #F8F8F9 !important; 
}

.park-timings-mobile-table .ant-table-thead > tr > th {
  background-color: #2263E3 !important; 
}

.park-timings-mobile-table .ant-table-tbody > tr:nth-child(2) {
  background-color: #F8F8F9 !important; 
}

.park-timings-table {
  border-radius: 14px !important;
  overflow: hidden;
  border: 1px solid #A0A1AB;
}

.park-timings-mobile-table {
  border-radius: 14px !important;
  overflow: hidden;
  border: 1px solid #A0A1AB;
}

