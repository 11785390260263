@media (min-width: 1440px) {
  .rules-image {
      content: url('../../../assets/images/rules/img-rules-page-1920px.webp');
      width: 100%;
  }
}

@media (max-width: 1440px) {
  .rules-image {
      content: url('../../../assets/images/rules/img-rules-page-1440px.webp');
      width: 100%;
  }
}

@media (max-width: 834px) {
  .rules-image {
      content: url('../../../assets/images/rules/img-rules-page-tab.webp');
      width: 100%;
      height: 380px;
  }
}

@media (max-width: 425px) {
  .rules-image {
      content: url('../../../assets/images/rules/img-rules-page-mobile.webp');
      width: 100%;
      height: 183px;
  }
}