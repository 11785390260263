.btn-pos {
  position: absolute;
  bottom: 1.325rem;
  right: 1.375rem;
}

.btn-sharavati{
  position: absolute;
  bottom: 1.325rem;
  right: 1.375rem;
  background: #ffff;
}

.object-cover {
  object-fit: cover;
}

.top-destination .item-height {
  min-height: 420px !important;
  border-radius: 1.5rem;
}

.custom-btn {
    position: absolute;
    bottom: 1.325rem;
    right: 1.375rem;
    padding: 0.75rem 1.5rem !important;
    border-radius: 1.5rem !important;
}

@media (max-width: 425px) {
  .ticketing-btn{
    width: 100%;
  }
}

.visiter-member{
  width: 100%;
  height: 378px;
  background: #32CC64;
  border-radius: 24px;
  color: #FFFFFF;
}

.visiter{
  line-height: 22.4px;
  letter-spacing: 0.5px;
}

@media (min-width: 1380px) {
  .btn-sharavati{
    position: absolute;
    bottom: 1.325rem;
    right: 30px;
  }
}

.visit-img {
  position: relative;
  overflow: hidden;
  border-radius: 24px;
  transition: transform 0.5s ease;
  max-height: 378px;
}

.visit-zoom {
  transition: transform 0.5s ease;
  width: 100%;
  min-height: 378px;
  object-fit: cover;
}

.visit-img:hover .visit-zoom {
  transform: scale(1.2);
}

.desti-custom{
  width: 100%;
  height: 378px;
  background: #2263E3;
  border-radius: 24px;
  color: #FFFFFF;
}


