.see-open{
  border: 1px solid;
}

@media (max-width: 425px) {
  .careet-text{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3; 
    line-height: 1.5em; 
    max-height: 4.5em;
  }
}