.ant-picker-calendar .ant-picker-date-panel .ant-picker-content th{
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 2rem;
    text-align: center;
}

.ant-picker-calendar .ant-picker-panel {
    border-top: none;
}
  
.section-number-1 {
    background-color: #303136;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}
  
.border-line {
    height: 2px;
    background-color: #A0A1AB;
}

.ant-picker-cell:not(.ant-picker-cell-in-view) {
    visibility: hidden !important;
}
  
@media (max-width: 426px) {
    .date-select{
        margin:  7px 0 !important;
        width: 40px !important;
        height: 40px !important;
    }

    .section-number-1{
        width: 30px !important;
        height: 30px !important;
        padding: 1.2rem !important;
    }

    .border-line {
        margin-bottom: 2rem !important;
    }

    /* .calendar-date {
        margin: 1rem !important;
    } */

    .calendar-date .ant-picker-panel {
        border-top: transparent;
    }

    .calendar-month {
        padding: 0 !important;
    }

    .mob-calendar {
        padding: 7px !important;
    }

    .ant-picker-calendar .ant-picker-date-panel .ant-picker-content th{
        font-size: 18px !important;
    }
}

@media (max-width: 1025.98px) {
    .ant-picker-calendar .ant-picker-date-panel .ant-picker-content th{        
        font-size: 20px;
        padding-bottom: 1rem;
    }
}

@media (max-width: 575px) {
    .text-mob {
        display: none !important;
    }
}