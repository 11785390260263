.gradient-overlay-rules {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 46.22%,
      #FFFFFF 60%
    );
    width: 100%;
    position: relative;
    
  }