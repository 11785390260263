.check-circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.anticon.check-circle svg {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.select-transport-img {
    border-radius: 16px;
    width: 162px !important;
    min-height: 158px !important;
    object-fit: cover;
}

.custom-book-btn {
    padding: 0.75rem 4rem !important;
    border-radius: 1.5rem !important;
    background: transparent;
}

.custom-back-btn {
    padding: 0.75rem 4rem !important;
    border-radius: 1.5rem !important;
    background: transparent;
}

@media (min-width: 992px) {
    .custom-back-btn {
        display: none;
    }
}

@media (max-width: 1199px) and (min-width: 768px) {
    .transport-image-container {
        flex-shrink: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: auto;
    }
    
    .select-transport-img {
        border-radius: 16px;
        width: 240px !important;
        min-height: 120px !important;
        object-fit: cover;
    }
}

@media (max-width: 425px) {
    .custom-book-btn{
        width: 100%;
    }
  
    .custom-back-btn {
        width: 100%;
    }

    .select-transport-img {
        width: 120px !important;
        min-height: 120px !important;
    }
}