.dynamic-height .ant-image {
    width: 64px !important;
    height: 64px !important;
}

.right-img .ant-image .ant-image-img{
    height: 564px;
    object-fit: cover;
}

@media (max-width: 834px) {
    .dynamic-height .ant-image {
        height: 48px !important; 
        width: 48px !important;
        margin-top: 10px;
    }

    .right-img .ant-image .ant-image-img{
        width: 632px;
        height: 378px;
    }
}

@media (max-width: 425px) {
    .right-img .ant-image .ant-image-img{
        max-width: 327px;
        height: 203px;
        margin-top: 1rem;
    }

    .close-button {
        top: 10px !important;
        right: 10px !important;
        font-size: 20px !important;
    }
}