.floating-form-item {
  position: relative;
  margin-bottom: 2.5rem;
}

.floating-input {
  padding: 12px;
  border-radius: 25px;
  border: 1px solid #d9d9d9;
  transition: all 0.3s ease;
}

.floating-input::placeholder {
  color: transparent; 
}

.floating-input:focus + .floating-label,
.floating-input:not(:placeholder-shown) + .floating-label {
  top: -10px; 
  left: 15px; 
  transform: translateY(0); 
}

.floating-input-phone {
  padding: 12px;
  border-radius: 25px;
  border: 1px solid #d9d9d9;
  transition: all 0.3s ease;
}

.floating-input-phone .ant-input {
  padding: 0 !important;
}

.floating-input-phone .ant-input-group-addon {
  padding: 0 8px !important;
}

.floating-input-phone::placeholder {
  color: transparent; 
}

.floating-input-phone:focus + .floating-label,
.floating-input-phone:not(:placeholder-shown) + .floating-label {
  top: -10px; 
  left: 15px; 
  transform: translateY(0); 
}


.floating-label {
  position: absolute;
  transform: translateY(-50%);
  color: #999;
  transition: all 0.3s ease;
  pointer-events: none;
  background-color: white;
}

.text-danger {
  color: #ff4d4f;
}

.phone-number-input {
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 24px;
}

.country-flag {
  margin-right: 8px;
}

.floating-form-item .ant-select-selector {
  padding: 0 !important;
}

.floating-form-item-phone .ant-select-selector {
  padding: 0 !important;
}