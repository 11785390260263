.plan-visit-btn{
  border: 1px solid;
}

@media (min-width: 1440px) {
   .map-location{
      height: 642px !important;
   }
}

@media (max-width: 1440px) {
  .map-location{
     height: 488px !important;
  }
}

@media (max-width: 834px) {
  .map-location{
     height: 378px !important;
  }
}
@media (max-width: 425px) {
  .map-location{
     height: 218px !important;
  }
}

  .park-time-table .ant-table-thead > tr > th {
    background-color: #7868CA !important; 
  }
 
  .park-time-table .custom-first-row {
    background-color: #FFFFFF !important; 
  }

  .park-time-table .custom-second-row {
    background-color: #F8F8F9 !important; 
  }
 
 .park-time-mobile-table .ant-table-thead > tr > th {
   background-color: #7868CA !important; 
 }
 
 .park-time-mobile-table .ant-table-tbody > tr:nth-child(2) {
   background-color: #F8F8F9 !important; 
 }

  .park-time-table {
    border-radius: 14px !important;
    overflow: hidden;
    border: 1px solid #A0A1AB;
  }

  .park-time-mobile-table {
    border-radius: 14px !important;
    overflow: hidden;
    border: 1px solid #A0A1AB;
  }



  