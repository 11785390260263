.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: black !important;
}

.custom-tabs .ant-tabs-tab {
    font-size: 18px; 
    font-weight: 400;
}


  