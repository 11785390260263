.shop-wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 24px;
    transition: transform 0.5s ease;
    max-height: 378px;
}
  
.shop-zoom {
    transition: transform 0.5s ease;
    width: 100%;
    min-height: 378px;
    object-fit: cover;
}
  
.shop-wrapper:hover .shop-zoom {
    transform: scale(1.2);
}
  
.card-footer {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    /* align-items: flex-end; */
    gap: 0.5rem;
    background-color: #ffff;
    padding: 0.5rem 1rem;
    border-radius: 20px !important;
    transition: opacity 0.3s ease;
}

.shopping-items {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
  
.card-content {
    display: none;
}
  
.shop-wrapper:hover .card-content {
    display: block;
}
  
.subtitle {
    margin: 0;
    color: #303136;
}

.shop-member{
    width: 100%;
    height: 378px;
    background: #2263E3;
    border-radius: 24px;
    color: #FFFFFF;
}
  