.footer-background {
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
}

@media (min-width: 1440px) {
    .footer-background {
        background-image: url('../../assets/images/footerimage/img-footer-1920px.webp');
        height: 800px;
    }
}

@media (max-width: 1440px) {
    .footer-background {
        background-image: url('../../assets/images/footerimage/img-footer-1440px.webp');
        height: 500px;
    }
}

@media (max-width: 834px) {
    .footer-background {
        background-image: url('../../assets/images/footerimage/img-footer-834px.webp');
        height: 360px;
    }
}
  
@media (max-width: 425px) {
    .footer-background {
        background-image: url('../../assets/images/footerimage/img-footer-375px.webp');
        height: 300px;
    }
}
  